import { ref } from 'vue'

const availableLanguages = ['fr', 'en', 'de', 'it']
const defaultLanguage = useNavigatorLanguage().language
import fr from '@/assets/translations/fr.json'
import de from '@/assets/translations/de.json'
import en from '@/assets/translations/en.json'
import it from '@/assets/translations/it.json'
import { isValidES3Identifier } from "@babel/types";
const lang = ref(localStorage.getItem('lang') ?? 'fr')

const translations: any = {
    de:de,
    fr:fr,
    it:it,
    en:en
}

export function useLanguage() {

    const setLanguage = (language: string | null | undefined) => {
        if (language === null || language === undefined) {
            language = defaultLanguage
        }
        language = language.slice(0, 2)
        if (!availableLanguages.includes(language)) {
            lang.value = defaultLanguage
        } else {
            lang.value = language
        }
    }

    const t = (value: string, replace: any = {}) => {
        if(value == null)
        {
            return ""
        }


        let translation = translations[lang.value]


        value.split('.').forEach((i) => {
            if (!translation[i]) {
                translation = value
                return value
            }
            translation = translation[i]
        })

        for (const r in replace) {
            translation = translation.replace(r, replace[r])
        }

        return translation
    }

    const t_exists = (value: string) => {

        return t(value) !== value
    }

    const formatDate = (date:Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based, so we add 1.
        const year = String(date.getFullYear());

        return `${day}.${month}.${year}`;
    }



    return { t, formatDate, setLanguage, lang, t_exists }
}
