import { defineStore } from "pinia";
import { Ref } from "vue";

export const useFlow = defineStore("flow", () => {

    const flow: Ref<string | undefined> = ref(localStorage.getItem('sos-amiante-flow') ?? undefined)

    const orderId: Ref<string | undefined> = ref(undefined);

    const flows = {
        'picture': {
            'steps': 7,
            'paymentStep': 6,
            'paymentValidationStep': 8
        },
        'sample': {
            'steps': 7,
            'paymentStep': 6,
            'paymentValidationStep': 7
        },
        'kit': {
            'steps': 3,
            'paymentStep': 2,
            'paymentValidationStep': 3
        },
        "contact": {
            'steps': 5,
            "paymentStep": 5,
            'paymentValidationStep': 5

        }
    }

    watch(() => flow.value, (newFlow, oldFlow) => {
        if (flow.value) {
            localStorage.setItem('sos-amiante-flow', flow.value);
        }
    })

    watch(() => orderId.value, () => {
        if (orderId.value) {
            localStorage.setItem('order_id', orderId.value);
        } else {
            localStorage.removeItem('order_id')
        }
    })

    if (localStorage.getItem('order_id')) {
        orderId.value = localStorage.getItem('order_id') ?? undefined
    }

    const currentFlow = computed(() => {
        if (flow.value) {
            return flows[flow.value as "sample" | "picture" | "kit"];
        } else {
            return null
        }
    })

    return {flow, currentFlow, orderId, flows};
})
