import { UseFetchOptions } from "#app";

export function useHttp() {


    const basePath = useRuntimeConfig().public.apiUrl
    const get = async (url: string) => {
        return request('GET', url);
    }

    const post = async (url: string, body: any) => {
        return request('POST', url, body);
    }

    const patch = async (url: string, body: any) => {
        return request('PATCH', url, body);
    }

    const request = async (method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH', url: string, body?: any) => {

        let options: UseFetchOptions<any> = {method: method, headers: {'Accept-Language':useLanguage().lang.value}};

        if (body) {
            options = {...options, body: body}
        }

        if (window.localStorage.getItem('token')) {
            options = {...options, headers: {...options.headers, Authorization: 'Bearer ' + window.localStorage.getItem('token')}}
        }


        let {data, error} = await useFetch(basePath + url, options);
        return {data, error};

    }

    return {get, post, patch}

}
