import { default as account_45createdRpvuMqMqJPMeta } from "/home/denis/sources/sos-amiante/client/pages/account-created.vue?macro=true";
import { default as account3SgTve8dzXMeta } from "/home/denis/sources/sos-amiante/client/pages/account.vue?macro=true";
import { default as confirmationL7Zg10cWSuMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/confirmation.vue?macro=true";
import { default as informationsXaHh4VdLPiMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/informations.vue?macro=true";
import { default as introET0sDw01JGMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/intro.vue?macro=true";
import { default as location81MTv57zWNMeta } from "/home/denis/sources/sos-amiante/client/pages/contact/location.vue?macro=true";
import { default as create_45account0txjptehDkMeta } from "/home/denis/sources/sos-amiante/client/pages/create-account.vue?macro=true";
import { default as create_45expert_45accountiA24Xuzv6VMeta } from "/home/denis/sources/sos-amiante/client/pages/create-expert-account.vue?macro=true";
import { default as indexhAJz01qG8EMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/index.vue?macro=true";
import { default as _91id_93kzpz9PxBpNMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/my-requests/[id].vue?macro=true";
import { default as indexd8URb4jOdSMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/my-requests/index.vue?macro=true";
import { default as _91id_93LURdRxz1u4Meta } from "/home/denis/sources/sos-amiante/client/pages/expert/public-requests/[id].vue?macro=true";
import { default as index2MFkfurPYbMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/public-requests/index.vue?macro=true";
import { default as settingsJ6MQtFOtcgMeta } from "/home/denis/sources/sos-amiante/client/pages/expert/settings.vue?macro=true";
import { default as forgot_45passwordGKiaU4QPLyMeta } from "/home/denis/sources/sos-amiante/client/pages/forgot-password.vue?macro=true";
import { default as indexjNdjUrOaiGMeta } from "/home/denis/sources/sos-amiante/client/pages/index.vue?macro=true";
import { default as indexjymy4wSnhPMeta } from "/home/denis/sources/sos-amiante/client/pages/kit/index.vue?macro=true";
import { default as loginnHZKh9InSSMeta } from "/home/denis/sources/sos-amiante/client/pages/login.vue?macro=true";
import { default as _91id_93gnCjwsEsb2Meta } from "/home/denis/sources/sos-amiante/client/pages/my-requests/[id].vue?macro=true";
import { default as indexjN0AlkBlE0Meta } from "/home/denis/sources/sos-amiante/client/pages/my-requests/index.vue?macro=true";
import { default as indexF0ZSTJXjfeMeta } from "/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/index.vue?macro=true";
import { default as new_45pictures0ryYis1jN6Meta } from "/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/new-pictures.vue?macro=true";
import { default as indexEg1gXeZeyzMeta } from "/home/denis/sources/sos-amiante/client/pages/orders/index.vue?macro=true";
import { default as payment_45validationpbzo0JVrV0Meta } from "/home/denis/sources/sos-amiante/client/pages/payment-validation.vue?macro=true";
import { default as paymentYgngP89evPMeta } from "/home/denis/sources/sos-amiante/client/pages/payment.vue?macro=true";
import { default as commentZiOmGcLZlnMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/comment.vue?macro=true";
import { default as construction_dateqpyPAKW1MiMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/construction_date.vue?macro=true";
import { default as introeYNNMpQh0sMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/intro.vue?macro=true";
import { default as locationiQPrEJiLBIMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/location.vue?macro=true";
import { default as picturesiiaJlFKt3dMeta } from "/home/denis/sources/sos-amiante/client/pages/picture/pictures.vue?macro=true";
import { default as profileQAow03oYFQMeta } from "/home/denis/sources/sos-amiante/client/pages/profile.vue?macro=true";
import { default as additional_45informationsWTu48JK5fdMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/additional-informations.vue?macro=true";
import { default as deadlineZxdLu1tjwKMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/deadline.vue?macro=true";
import { default as how_45toc1MECWIj4gMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/how-to.vue?macro=true";
import { default as introymK7md3EgtMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/intro.vue?macro=true";
import { default as materialBEYgJhPrvEMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/material.vue?macro=true";
import { default as edityja1TnNNOCMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/samples/edit.vue?macro=true";
import { default as indexQS80WQLsybMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/samples/index.vue?macro=true";
import { default as sending6YVswWU59QMeta } from "/home/denis/sources/sos-amiante/client/pages/sample/sending.vue?macro=true";
export default [
  {
    name: account_45createdRpvuMqMqJPMeta?.name ?? "account-created",
    path: account_45createdRpvuMqMqJPMeta?.path ?? "/account-created",
    meta: account_45createdRpvuMqMqJPMeta || {},
    alias: account_45createdRpvuMqMqJPMeta?.alias || [],
    redirect: account_45createdRpvuMqMqJPMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/account-created.vue").then(m => m.default || m)
  },
  {
    name: account3SgTve8dzXMeta?.name ?? "account",
    path: account3SgTve8dzXMeta?.path ?? "/account",
    meta: account3SgTve8dzXMeta || {},
    alias: account3SgTve8dzXMeta?.alias || [],
    redirect: account3SgTve8dzXMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/account.vue").then(m => m.default || m)
  },
  {
    name: confirmationL7Zg10cWSuMeta?.name ?? "contact-confirmation",
    path: confirmationL7Zg10cWSuMeta?.path ?? "/contact/confirmation",
    meta: confirmationL7Zg10cWSuMeta || {},
    alias: confirmationL7Zg10cWSuMeta?.alias || [],
    redirect: confirmationL7Zg10cWSuMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/confirmation.vue").then(m => m.default || m)
  },
  {
    name: informationsXaHh4VdLPiMeta?.name ?? "contact-informations",
    path: informationsXaHh4VdLPiMeta?.path ?? "/contact/informations",
    meta: informationsXaHh4VdLPiMeta || {},
    alias: informationsXaHh4VdLPiMeta?.alias || [],
    redirect: informationsXaHh4VdLPiMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/informations.vue").then(m => m.default || m)
  },
  {
    name: introET0sDw01JGMeta?.name ?? "contact-intro",
    path: introET0sDw01JGMeta?.path ?? "/contact/intro",
    meta: introET0sDw01JGMeta || {},
    alias: introET0sDw01JGMeta?.alias || [],
    redirect: introET0sDw01JGMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/intro.vue").then(m => m.default || m)
  },
  {
    name: location81MTv57zWNMeta?.name ?? "contact-location",
    path: location81MTv57zWNMeta?.path ?? "/contact/location",
    meta: location81MTv57zWNMeta || {},
    alias: location81MTv57zWNMeta?.alias || [],
    redirect: location81MTv57zWNMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/contact/location.vue").then(m => m.default || m)
  },
  {
    name: create_45account0txjptehDkMeta?.name ?? "create-account",
    path: create_45account0txjptehDkMeta?.path ?? "/create-account",
    meta: create_45account0txjptehDkMeta || {},
    alias: create_45account0txjptehDkMeta?.alias || [],
    redirect: create_45account0txjptehDkMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: create_45expert_45accountiA24Xuzv6VMeta?.name ?? "create-expert-account",
    path: create_45expert_45accountiA24Xuzv6VMeta?.path ?? "/create-expert-account",
    meta: create_45expert_45accountiA24Xuzv6VMeta || {},
    alias: create_45expert_45accountiA24Xuzv6VMeta?.alias || [],
    redirect: create_45expert_45accountiA24Xuzv6VMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/create-expert-account.vue").then(m => m.default || m)
  },
  {
    name: indexhAJz01qG8EMeta?.name ?? "expert",
    path: indexhAJz01qG8EMeta?.path ?? "/expert",
    meta: indexhAJz01qG8EMeta || {},
    alias: indexhAJz01qG8EMeta?.alias || [],
    redirect: indexhAJz01qG8EMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kzpz9PxBpNMeta?.name ?? "expert-my-requests-id",
    path: _91id_93kzpz9PxBpNMeta?.path ?? "/expert/my-requests/:id()",
    meta: _91id_93kzpz9PxBpNMeta || {},
    alias: _91id_93kzpz9PxBpNMeta?.alias || [],
    redirect: _91id_93kzpz9PxBpNMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/my-requests/[id].vue").then(m => m.default || m)
  },
  {
    name: indexd8URb4jOdSMeta?.name ?? "expert-my-requests",
    path: indexd8URb4jOdSMeta?.path ?? "/expert/my-requests",
    meta: indexd8URb4jOdSMeta || {},
    alias: indexd8URb4jOdSMeta?.alias || [],
    redirect: indexd8URb4jOdSMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LURdRxz1u4Meta?.name ?? "expert-public-requests-id",
    path: _91id_93LURdRxz1u4Meta?.path ?? "/expert/public-requests/:id()",
    meta: _91id_93LURdRxz1u4Meta || {},
    alias: _91id_93LURdRxz1u4Meta?.alias || [],
    redirect: _91id_93LURdRxz1u4Meta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/public-requests/[id].vue").then(m => m.default || m)
  },
  {
    name: index2MFkfurPYbMeta?.name ?? "expert-public-requests",
    path: index2MFkfurPYbMeta?.path ?? "/expert/public-requests",
    meta: index2MFkfurPYbMeta || {},
    alias: index2MFkfurPYbMeta?.alias || [],
    redirect: index2MFkfurPYbMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/public-requests/index.vue").then(m => m.default || m)
  },
  {
    name: settingsJ6MQtFOtcgMeta?.name ?? "expert-settings",
    path: settingsJ6MQtFOtcgMeta?.path ?? "/expert/settings",
    meta: settingsJ6MQtFOtcgMeta || {},
    alias: settingsJ6MQtFOtcgMeta?.alias || [],
    redirect: settingsJ6MQtFOtcgMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/expert/settings.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordGKiaU4QPLyMeta?.name ?? "forgot-password",
    path: forgot_45passwordGKiaU4QPLyMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordGKiaU4QPLyMeta || {},
    alias: forgot_45passwordGKiaU4QPLyMeta?.alias || [],
    redirect: forgot_45passwordGKiaU4QPLyMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexjNdjUrOaiGMeta?.name ?? "index",
    path: indexjNdjUrOaiGMeta?.path ?? "/",
    meta: indexjNdjUrOaiGMeta || {},
    alias: indexjNdjUrOaiGMeta?.alias || [],
    redirect: indexjNdjUrOaiGMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjymy4wSnhPMeta?.name ?? "kit",
    path: indexjymy4wSnhPMeta?.path ?? "/kit",
    meta: indexjymy4wSnhPMeta || {},
    alias: indexjymy4wSnhPMeta?.alias || [],
    redirect: indexjymy4wSnhPMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/kit/index.vue").then(m => m.default || m)
  },
  {
    name: loginnHZKh9InSSMeta?.name ?? "login",
    path: loginnHZKh9InSSMeta?.path ?? "/login",
    meta: loginnHZKh9InSSMeta || {},
    alias: loginnHZKh9InSSMeta?.alias || [],
    redirect: loginnHZKh9InSSMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gnCjwsEsb2Meta?.name ?? "my-requests-id",
    path: _91id_93gnCjwsEsb2Meta?.path ?? "/my-requests/:id()",
    meta: _91id_93gnCjwsEsb2Meta || {},
    alias: _91id_93gnCjwsEsb2Meta?.alias || [],
    redirect: _91id_93gnCjwsEsb2Meta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/my-requests/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjN0AlkBlE0Meta?.name ?? "my-requests",
    path: indexjN0AlkBlE0Meta?.path ?? "/my-requests",
    meta: indexjN0AlkBlE0Meta || {},
    alias: indexjN0AlkBlE0Meta?.alias || [],
    redirect: indexjN0AlkBlE0Meta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0ZSTJXjfeMeta?.name ?? "orders-order_uuid",
    path: indexF0ZSTJXjfeMeta?.path ?? "/orders/:order_uuid()",
    meta: indexF0ZSTJXjfeMeta || {},
    alias: indexF0ZSTJXjfeMeta?.alias || [],
    redirect: indexF0ZSTJXjfeMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: new_45pictures0ryYis1jN6Meta?.name ?? "orders-order_uuid-new-pictures",
    path: new_45pictures0ryYis1jN6Meta?.path ?? "/orders/:order_uuid()/new-pictures",
    meta: new_45pictures0ryYis1jN6Meta || {},
    alias: new_45pictures0ryYis1jN6Meta?.alias || [],
    redirect: new_45pictures0ryYis1jN6Meta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/orders/[order_uuid]/new-pictures.vue").then(m => m.default || m)
  },
  {
    name: indexEg1gXeZeyzMeta?.name ?? "orders",
    path: indexEg1gXeZeyzMeta?.path ?? "/orders",
    meta: indexEg1gXeZeyzMeta || {},
    alias: indexEg1gXeZeyzMeta?.alias || [],
    redirect: indexEg1gXeZeyzMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45validationpbzo0JVrV0Meta?.name ?? "payment-validation",
    path: payment_45validationpbzo0JVrV0Meta?.path ?? "/payment-validation",
    meta: payment_45validationpbzo0JVrV0Meta || {},
    alias: payment_45validationpbzo0JVrV0Meta?.alias || [],
    redirect: payment_45validationpbzo0JVrV0Meta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/payment-validation.vue").then(m => m.default || m)
  },
  {
    name: paymentYgngP89evPMeta?.name ?? "payment",
    path: paymentYgngP89evPMeta?.path ?? "/payment",
    meta: paymentYgngP89evPMeta || {},
    alias: paymentYgngP89evPMeta?.alias || [],
    redirect: paymentYgngP89evPMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: commentZiOmGcLZlnMeta?.name ?? "picture-comment",
    path: commentZiOmGcLZlnMeta?.path ?? "/picture/comment",
    meta: commentZiOmGcLZlnMeta || {},
    alias: commentZiOmGcLZlnMeta?.alias || [],
    redirect: commentZiOmGcLZlnMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/comment.vue").then(m => m.default || m)
  },
  {
    name: construction_dateqpyPAKW1MiMeta?.name ?? "picture-construction_date",
    path: construction_dateqpyPAKW1MiMeta?.path ?? "/picture/construction_date",
    meta: construction_dateqpyPAKW1MiMeta || {},
    alias: construction_dateqpyPAKW1MiMeta?.alias || [],
    redirect: construction_dateqpyPAKW1MiMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/construction_date.vue").then(m => m.default || m)
  },
  {
    name: introeYNNMpQh0sMeta?.name ?? "picture-intro",
    path: introeYNNMpQh0sMeta?.path ?? "/picture/intro",
    meta: introeYNNMpQh0sMeta || {},
    alias: introeYNNMpQh0sMeta?.alias || [],
    redirect: introeYNNMpQh0sMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/intro.vue").then(m => m.default || m)
  },
  {
    name: locationiQPrEJiLBIMeta?.name ?? "picture-location",
    path: locationiQPrEJiLBIMeta?.path ?? "/picture/location",
    meta: locationiQPrEJiLBIMeta || {},
    alias: locationiQPrEJiLBIMeta?.alias || [],
    redirect: locationiQPrEJiLBIMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/location.vue").then(m => m.default || m)
  },
  {
    name: picturesiiaJlFKt3dMeta?.name ?? "picture-pictures",
    path: picturesiiaJlFKt3dMeta?.path ?? "/picture/pictures",
    meta: picturesiiaJlFKt3dMeta || {},
    alias: picturesiiaJlFKt3dMeta?.alias || [],
    redirect: picturesiiaJlFKt3dMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/picture/pictures.vue").then(m => m.default || m)
  },
  {
    name: profileQAow03oYFQMeta?.name ?? "profile",
    path: profileQAow03oYFQMeta?.path ?? "/profile",
    meta: profileQAow03oYFQMeta || {},
    alias: profileQAow03oYFQMeta?.alias || [],
    redirect: profileQAow03oYFQMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: additional_45informationsWTu48JK5fdMeta?.name ?? "sample-additional-informations",
    path: additional_45informationsWTu48JK5fdMeta?.path ?? "/sample/additional-informations",
    meta: additional_45informationsWTu48JK5fdMeta || {},
    alias: additional_45informationsWTu48JK5fdMeta?.alias || [],
    redirect: additional_45informationsWTu48JK5fdMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/additional-informations.vue").then(m => m.default || m)
  },
  {
    name: deadlineZxdLu1tjwKMeta?.name ?? "sample-deadline",
    path: deadlineZxdLu1tjwKMeta?.path ?? "/sample/deadline",
    meta: deadlineZxdLu1tjwKMeta || {},
    alias: deadlineZxdLu1tjwKMeta?.alias || [],
    redirect: deadlineZxdLu1tjwKMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/deadline.vue").then(m => m.default || m)
  },
  {
    name: how_45toc1MECWIj4gMeta?.name ?? "sample-how-to",
    path: how_45toc1MECWIj4gMeta?.path ?? "/sample/how-to",
    meta: how_45toc1MECWIj4gMeta || {},
    alias: how_45toc1MECWIj4gMeta?.alias || [],
    redirect: how_45toc1MECWIj4gMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/how-to.vue").then(m => m.default || m)
  },
  {
    name: introymK7md3EgtMeta?.name ?? "sample-intro",
    path: introymK7md3EgtMeta?.path ?? "/sample/intro",
    meta: introymK7md3EgtMeta || {},
    alias: introymK7md3EgtMeta?.alias || [],
    redirect: introymK7md3EgtMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/intro.vue").then(m => m.default || m)
  },
  {
    name: materialBEYgJhPrvEMeta?.name ?? "sample-material",
    path: materialBEYgJhPrvEMeta?.path ?? "/sample/material",
    meta: materialBEYgJhPrvEMeta || {},
    alias: materialBEYgJhPrvEMeta?.alias || [],
    redirect: materialBEYgJhPrvEMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/material.vue").then(m => m.default || m)
  },
  {
    name: edityja1TnNNOCMeta?.name ?? "sample-samples-edit",
    path: edityja1TnNNOCMeta?.path ?? "/sample/samples/edit",
    meta: edityja1TnNNOCMeta || {},
    alias: edityja1TnNNOCMeta?.alias || [],
    redirect: edityja1TnNNOCMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/samples/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQS80WQLsybMeta?.name ?? "sample-samples",
    path: indexQS80WQLsybMeta?.path ?? "/sample/samples",
    meta: indexQS80WQLsybMeta || {},
    alias: indexQS80WQLsybMeta?.alias || [],
    redirect: indexQS80WQLsybMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/samples/index.vue").then(m => m.default || m)
  },
  {
    name: sending6YVswWU59QMeta?.name ?? "sample-sending",
    path: sending6YVswWU59QMeta?.path ?? "/sample/sending",
    meta: sending6YVswWU59QMeta || {},
    alias: sending6YVswWU59QMeta?.alias || [],
    redirect: sending6YVswWU59QMeta?.redirect,
    component: () => import("/home/denis/sources/sos-amiante/client/pages/sample/sending.vue").then(m => m.default || m)
  }
]